
import JobList from '@/components/parts/jobs/JobList.vue'
export default {
  components: {
    JobList,
  },
  props: {
    section: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    this.$emit('sectionMounted')
  },
}
